import {
  Badge,
  Button,
  Dropdown,
  Image,
  Layout,
  Menu,
  notification,
  Space,
  Switch,
  Typography,
} from "antd";
import {
  AppstoreOutlined,
  BankOutlined,
  BellOutlined,
  DollarOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  QuestionCircleOutlined,
  SafetyOutlined,
  UserOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { TriggerLogout } from "Redux/LogoutRedux/LogoutAction";
import { useEffect, useState } from "react";
import { GetNotificationRequest } from "Redux/NotificationRedux/NotificationActions";
import NotificationList from "Components/NotificationList/NotificationList";
import { API } from "Redux/Apis";
import { useHistory } from "react-router";
import { GetProfileRequest } from "Views/KYC/ProfileActions";

const { Sider, Content, Footer, Header } = Layout;

function getFaviconEl() {
  return document.getElementById("favIcon");
}
function getFavTitleEl() {
  return document.getElementById("favTitle");
}

var colorCode: any = window.localStorage.getItem("localColorCode");
const retrivedColorCodes = JSON.parse(colorCode);

interface ProtectedLayoutProps {
  children?: any;
}

const ProtectedLayout: React.FC<ProtectedLayoutProps> = ({ children }) => {
  const [viewed, setViewed] = useState<boolean>(false);
  const history = useHistory();
  // const [kycStatusCheck, setKycStatusCheck] = useState<any>();

  const dispatch = useDispatch();
  const UserName = useSelector(
    (state: RootStateOrAny) => state.LoginReducers.Login.profile
  );
  const Profile = useSelector(
    (state: RootStateOrAny) => state.ProfileReducers.ProfileDetails
  );

  const Notifications = useSelector(
    (state: RootStateOrAny) => state.NotificationReducers.Notifications
  );

  // const kycStatus = useSelector(
  //   (state: RootStateOrAny) => state.ProfileReducers.ProfileDetails.kycStatus
  // );

  const kycStatus = localStorage.getItem("kycStatus");

  const NotificationStatus = useSelector(
    (state: RootStateOrAny) =>
      state.LoginReducers.Login.profile.informativeNotification
  );
  const handleLogout = () => {
    dispatch(TriggerLogout());
    sessionStorage.removeItem("ABC");
    sessionStorage.removeItem("persist:root");
    localStorage.clear();
  };

  const handleNotificationViewed = async () => {
    try {
      const response = await API.get("/customer/notification/viewed");
      if (response.status === 200) {
        setViewed(true);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleChange = async () => {
    try {
      const response = await API.get("/customer/toggle-notification");
      if (response.status === 200) {
        notification.success({
          message: response.data.informativeNotification
            ? "Notification Enabled!"
            : "Notification Disabled!",
        });
      }
    } catch (error: any) {
      notification.error({
        message: "Something Went Wrong!",
        description: error?.response?.data?.message,
      });
    }
  };

  useEffect(() => {
    dispatch(GetNotificationRequest());
    setInterval(() => {
      dispatch(GetNotificationRequest());
    }, 30 * 1000);
  }, [dispatch]);

  useEffect(() => {
    dispatch(GetProfileRequest());
  }, [Notifications?.count]);

  useEffect(() => {
    if (retrivedColorCodes !== null) {
      const favicon: any = getFaviconEl(); // Accessing favicon element
      favicon.href =
        retrivedColorCodes !== null ? retrivedColorCodes?.titleBarIcon : "";
      const favTitle: any = getFavTitleEl(); // Accessing favicon element
      favTitle.innerHTML =
        retrivedColorCodes !== null ? retrivedColorCodes?.titleBarText : "";
    }
  }, [retrivedColorCodes]);

  const menu = (
    <Menu>
      <Menu.Item>
        <Content
          style={{
            padding: 10,
            borderRadius: 4,
            backgroundColor: `${retrivedColorCodes?.primaryColor}`,
          }}
        >
          <Space direction="vertical">
            <Typography.Text
              style={{
                fontSize: 12,
                color: `${retrivedColorCodes?.blackColor}`,
              }}
            >
              Loyalty Points:
            </Typography.Text>
            <Typography.Text
              style={{
                fontSize: 18,
                color: `${retrivedColorCodes?.blackColor}`,
              }}
            >
              {Profile?.loyaltyPoints}
            </Typography.Text>
          </Space>
        </Content>
      </Menu.Item>
      <Menu.Item onClick={() => history.push("/my-profile")}>Profile</Menu.Item>
      <Menu.Item onClick={() => history.push("/referrals")}>
        Referrals
      </Menu.Item>
      <Menu.Item>
        Notification{" "}
        <Switch
          style={{ backgroundColor: `${retrivedColorCodes?.primaryColor}` }}
          defaultChecked={NotificationStatus}
          onChange={handleChange}
        />
      </Menu.Item>
      <Menu.Item onClick={() => history.push("/change-password")}>
        Change Password
      </Menu.Item>
      <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
    </Menu>
  );

  const NotificationData = <NotificationList history={history} />;

  return (
    <Layout>
      <div
        style={
          {
            "--customBgColor": `${retrivedColorCodes?.primaryColor}`,
            "--customTextColor": `${retrivedColorCodes?.blackColor}`,
          } as React.CSSProperties
        }
      >
        <Header
          className="flex-space-between"
          style={{ backgroundColor: "white" }}
        >
          <Image
            onClick={() => history.push("/")}
            preview={false}
            src={retrivedColorCodes?.logo}
            width={150}
            style={{ marginLeft: -20, cursor: "pointer" }}
          />
          <Space size="middle">
            <Badge count={viewed ? 0 : Notifications?.count} size="small">
              <Dropdown
                trigger={["click"]}
                overlay={NotificationData}
                placement="bottomCenter"
              >
                <BellOutlined
                  onClick={handleNotificationViewed}
                  style={{ fontSize: 20, margin: 0, color: "#dc992b" }}
                />
              </Dropdown>
            </Badge>
            <Typography.Text>
              <Dropdown
                overlay={menu}
                placement="bottomCenter"
                trigger={["click"]}
              >
                <Button type="text" style={{ color: "#dc992b" }}>
                  {UserName && UserName.customerFirstName !== null
                    ? UserName.customerFirstName
                    : ""}{" "}
                  {UserName && UserName.customerLastName !== null
                    ? UserName.customerLastName
                    : "User"}
                </Button>
              </Dropdown>
            </Typography.Text>
          </Space>
        </Header>
        <Layout>
          <Sider
            width={250}
            style={{ minHeight: "90vh", maxHeight: "1000vh" }}
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              // console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              // console.log(collapsed, type);
            }}
          >
            <Menu
              style={{ marginTop: 20 }}
              mode="inline"
              selectedKeys={[history.location.pathname]}
              onClick={(e: any) => history.push(e.key)}
            >
              <Menu.Item
                key="/"
                icon={<AppstoreOutlined style={{ fontSize: 20 }} />}
              >
                Dashboard
              </Menu.Item>
              <Menu.Item
                disabled={kycStatus !== "VERIFIED"}
                key="/activities"
                icon={<DollarOutlined style={{ fontSize: 20 }} />}
              >
                Activities
              </Menu.Item>
              <Menu.Item
                disabled={kycStatus !== "VERIFIED"}
                key="/linked-bank-accounts"
                icon={<BankOutlined style={{ fontSize: 20 }} />}
              >
                Linked Bank Accounts
              </Menu.Item>
              <Menu.Item
                disabled={kycStatus !== "VERIFIED"}
                key="/saved-recipients"
                icon={<UserOutlined style={{ fontSize: 20 }} />}
              >
                Saved Recipients
              </Menu.Item>
              <Menu.Item
                disabled={kycStatus !== "VERIFIED"}
                key="/privacypolicy"
                icon={<SafetyOutlined style={{ fontSize: 20 }} />}
              >
                Privacy Policy
              </Menu.Item>
              <Menu.Item
                disabled={kycStatus !== "VERIFIED"}
                key="/termsandconditions"
                icon={<FilePdfOutlined style={{ fontSize: 20 }} />}
              >
                Terms & Conditions
              </Menu.Item>
              <Menu.Item
                disabled={kycStatus !== "VERIFIED"}
                key="/help"
                icon={<QuestionCircleOutlined style={{ fontSize: 20 }} />}
              >
                Help & Support
              </Menu.Item>
              <Menu.Item
                disabled={kycStatus !== "VERIFIED"}
                key="/FAQ"
                icon={<FileTextOutlined style={{ fontSize: 20 }} />}
              >
                FAQ
              </Menu.Item>
              {kycStatus !== "VERIFIED" && (
                <Menu.Item
                  key="/KYC"
                  icon={<AuditOutlined style={{ fontSize: 20 }} />}
                >
                  KYC
                </Menu.Item>
              )}
            </Menu>
          </Sider>
          <Layout style={{ padding: "0 24px 24px" }}>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              {children}
            </Content>
          </Layout>
        </Layout>
        <Footer className="flex-center" style={{ backgroundColor: "#fff" }}>
          &copy; POWERED BY AUSNEP IT SOLUTIONS
        </Footer>
      </div>
    </Layout>
  );
};

export default ProtectedLayout;
