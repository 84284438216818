import { Button, Card, Form, Image, Input, Typography } from "antd";
import laxmipay from "Assets/logo.png";
import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PostLoginRequest } from "./LoginRedux/LoginActions";
import axios from "axios";

interface LoginProps {
  history?: any;
}
interface Foo {
  [agentCode: string]: string;
}

function getFaviconEl() {
  return document.getElementById("favIcon");
}
function getFavTitleEl() {
  return document.getElementById("favTitle");
}

var colorCode: any = window.localStorage.getItem("localColorCode");
const retrivedColorCodes = JSON.parse(colorCode);

const Login: React.FC<LoginProps> = ({ history }) => {
  const dispatch = useDispatch();
  const [isSuccesFetch, setIsSuccessFetch] = useState<boolean>(false);

  const handleLogin = (values: any) => {
    values.agentCode = process.env.REACT_APP_API_AGENTCODE_FAKE;
    dispatch(PostLoginRequest(values));
  };

  const LoggedIn = useSelector((state: RootStateOrAny) => state.LoginReducers);

  function setWithExpiry(key: string, value: any, ttl: any) {
    const now = new Date();
    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    sessionStorage.setItem(key, JSON.stringify(item));
  }

  async function handleApiCall() {
    try {
      const response = await axios.post(`/get-outline`, {
        agentCode: "LAX865",
      });
      setIsSuccessFetch(true);

      window.localStorage.setItem(
        "localColorCode",
        JSON.stringify(response.data)
      );
    } catch (error) {
      // console.log(error);
    }
  }

  useEffect(() => {
    handleApiCall();
  }, []);

  useEffect(() => {
    if (LoggedIn.Login) {
      if (LoggedIn.Login.profile.kycStatus === "ADD_INFO_PENDING") {
        window.location.href = "/KYC";
        localStorage.setItem("kycStatus", LoggedIn.Login.profile.kycStatus);
        localStorage.setItem("kycChecked", "no");
      } else {
        localStorage.setItem("kycStatus", LoggedIn.Login.profile.kycStatus);
        // localStorage.setItem("kycChecked", "yes");
        localStorage.setItem("kycChecked", "no");
        window.location.href = "/";
      }
      setWithExpiry("ABC", LoggedIn.Login.accessToken, 14 * 60 * 1000);
    }
  }, [LoggedIn, history]);

  useEffect(() => {
    if (isSuccesFetch && retrivedColorCodes === null) {
      window.location.reload();
    }
  }, [isSuccesFetch]);

  useEffect(() => {
    if (retrivedColorCodes !== null) {
      const favicon: any = getFaviconEl(); // Accessing favicon element
      favicon.href =
        retrivedColorCodes !== null ? retrivedColorCodes?.titleBarIcon : "";
      const favTitle: any = getFavTitleEl(); // Accessing favicon element
      favTitle.innerHTML =
        retrivedColorCodes !== null ? retrivedColorCodes?.titleBarText : "";
    }
  }, [retrivedColorCodes]);

  return (
    <div>
      {retrivedColorCodes !== null ? (
        <Card style={{ width: 360, height: "auto" }}>
          <Image
            style={{
              borderRadius: "0.5rem",
              background: "#f4822a20",
            }}
            src={retrivedColorCodes?.logo}
            preview={false}
          />
          <Form
            onFinish={handleLogin}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
              marginTop: "1rem",
            }}
            layout="vertical"
          >
            {/* <Form.Item>
              <Typography.Text>Please Login to continue</Typography.Text>
            </Form.Item> */}
            <h3>Please Login to Continue</h3>
            <Form.Item
              name="username"
              style={{ width: "100%", margin: "0" }}
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Please input your Email",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ width: "100%", margin: "0" }}
              name="password"
              label="Password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Button
              loading={LoggedIn.loading}
              htmlType="submit"
              style={{
                width: "100%",
                marginTop: "1rem",
                backgroundColor: `${retrivedColorCodes?.primaryColor}`,
                color: `${retrivedColorCodes?.blackColor}`,
              }}
            >
              Login
            </Button>

            <Typography.Text>
              Don't have an account? <Link to="/auth/signup">Signup.</Link>
            </Typography.Text>

            <Typography.Text
              className="link-to"
              style={{ marginBottom: "1rem" }}
              onClick={() => history.push("/auth/forgot-password")}
            >
              Forgot Password?
            </Typography.Text>
          </Form>
        </Card>
      ) : (
        <div className="login--loading">
          <span>Loading</span>
        </div>
      )}
    </div>
  );
};

export default Login;
