import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, notification, Row, Space } from "antd";
import { API } from "Redux/Apis";
import moment from "moment";

import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { ActivitiesActionsRequest } from "Views/Activities/ActivitiesActions";
import { useParams } from "react-router";

interface SuccessProps {}
export function getWithExpiry(key: string) {
  const itemStr = sessionStorage.getItem(key)
    ? sessionStorage.getItem(key)
    : null;
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item: any = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    sessionStorage.removeItem(key);
    return null;
  }
  return item.value;
}

const Success: React.FC<SuccessProps> = () => {
  const [pdfLink, setPdfLink] = useState<string>("");
  const pageNo = 1;
  const endDate = moment().format().slice(0, 10);
  const startDate = moment().subtract(30, "days").format().slice(0, 10);
  const dispatch = useDispatch();
  const bankOrBranch = useSelector(
    (state: RootStateOrAny) => state.PoliPayReducers.bankOrBranch
  );
  const exchangeRates = useSelector(
    (state: RootStateOrAny) => state.DashboardReducers.ExchangeRates
  );
  const PoliPayReducers = useSelector(
    (state: RootStateOrAny) => state.PoliPayReducers
  );

  const paymentMethod = useSelector(
    (state: RootStateOrAny) => state.PoliPayReducers.paymentMethod
  );
  const bankDepositId = useSelector(
    (state: RootStateOrAny) => state.PoliPayReducers.bankDepositId
  );
  const aud = useSelector(
    (state: RootStateOrAny) => state.PoliPayReducers.transactionAmount
  );
  const npr = useSelector(
    (state: RootStateOrAny) => state.PoliPayReducers.receivingAmount
  );
  const PoliPay = useSelector(
    (state: RootStateOrAny) => state.PoliPayReducers.PoliPayRes
  );
  const [isDownloadedPdf, setIsDownloadedPdf] = useState<boolean>(false);

  const urltoken = useSelector(
    (state: RootStateOrAny) => state.PoliPayReducers?.PoliPayRes?.split("=")[1]
  );

  const remittanceDepositId = useSelector(
    (state: RootStateOrAny) => state.PoliPayReducers.remittanceDepositId
  );
  const handleGetTransaction = async (urltoken: any) => {
    // alert("token vako thau ma")
    try {
      const response = await API.get(
        `/transfer/poli-pay/get-transaction?token=${urltoken}`
      );
      if (response.status === 200) {
        if (bankOrBranch === "bank") {
          handlePayment1({
            paymentMethod: paymentMethod,
            id: bankDepositId,
            transactionId: response.data.TransactionID,
          });
        } else {
          handlePayment2({
            paymentMethod: paymentMethod,
            id: remittanceDepositId,
            transactionId: response.data.TransactionID,
          });
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const handlePayment1 = async ({ paymentMethod, id, transactionId }: any) => {
    // alert("some error may be here")
    try {
      const response = await API.put("/transfer/payment", {
        notificationFlag: PoliPayReducers.notificationFlag,
        transactionId: transactionId,
        transactionDate: moment().format().slice(0, 19),
        transactionDescription: `${paymentMethod.paymentMethod} ${bankOrBranch} ${id}`,
        paymentMethod: paymentMethod.paymentMethod,
        transactionAmount: aud,
        bankDepositId: id,
        transferFee: exchangeRates.transferFee,
        isLoyaltyPointRedeemed: PoliPayReducers?.useLoyaltyPoints
          ? true
          : false,
        receivingCurrency: "NPR",
        receivingAmount: npr,
        loyaltyPoints: PoliPayReducers.useLoyaltyPoints
          ? exchangeRates.loyaltyPoints
          : "",
        equivalentLoyaltyAmount: PoliPayReducers.useLoyaltyPoints
          ? exchangeRates.convertedLoyaltyAmount
          : "",
      });
      if (response.status === 200) {
        notification.success({ message: "Payment Successful!" });
        setTimeout(function () {
          handleGetPdf(response.data.paymentId);
        }, 500);
      } else
        notification.error({
          message: "Something Went Wrong, Please try again later.",
        });
    } catch (error) {
      // console.log(error);
    }
  };

  const handlePayment2 = async ({ paymentMethod, id, transactionId }: any) => {
    try {
      const response = await API.put("/transfer/payment", {
        notificationFlag: PoliPayReducers.notificationFlag,
        transactionId: transactionId,
        transactionDate: moment().format().slice(0, 19),
        transactionDescription: `${paymentMethod.paymentMethod} ${bankOrBranch} ${id}`,
        paymentMethod: paymentMethod.paymentMethod,
        transactionAmount: aud,
        remittanceDepositId: id,
        transferFee: exchangeRates.transferFee,
        isLoyaltyPointRedeemed: PoliPayReducers?.useLoyaltyPoints
          ? true
          : false,
        receivingCurrency: "NPR",
        receivingAmount: npr,
        loyaltyPoints: PoliPayReducers.useLoyaltyPoints
          ? exchangeRates.loyaltyPoints
          : "",
        equivalentLoyaltyAmount: PoliPayReducers.useLoyaltyPoints
          ? exchangeRates.convertedLoyaltyAmount
          : "",
      });
      if (response.status === 200) {
        setTimeout(function () {
          handleGetPdf(response.data.paymentId);
        }, 500);
        notification.success({ message: "Payment Successful!" });
      } else
        notification.error({
          message: "Something Went Wrong, Please try again later.",
        });
    } catch (error) {
      // console.log(error);
    }
  };

  let myDate = new Date().toString().split("+")[1];
  let myString = `+${myDate.slice(0, 2)}:${myDate.slice(2, 4)}`;

  const handleGetPdf = async (id: any) => {
    setIsDownloadedPdf(true);
    const reportPdfDoc = await API.get(
      `/transfer/payment/new-format/get-pdf/${id}/${myString}`,
      {
        headers: { Authorization: "Bearer " + getWithExpiry("ABC") },
        responseType: "blob",
      }
    );

    // console.log("The reportddsfcod",reportPdfDoc)
    if (reportPdfDoc?.status === 200) {
      const blob = new Blob([reportPdfDoc.data], {
        type: "application/pdf",
      });
      setIsDownloadedPdf(false);

      const computedFileName = `hamroremit_activity_file.pdf`;
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = computedFileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
  useEffect(() => {
    if (PoliPay !== false) {
      handleGetTransaction(urltoken);
    }
  }, [PoliPay, urltoken]);
  useEffect(() => {
    dispatch(ActivitiesActionsRequest({ startDate, endDate, pageNo }));
  });
  return (
    <Card>
      <Row justify="center" align="middle">
        <Col className="flex-center">
          <Space direction="vertical">
            <Alert message="Successfully Paid!" type="success" showIcon />
            <Alert
              message="You can view the details of payment on Activities Tab!"
              type="info"
              showIcon
              style={{ marginTop: 10 }}
            />
            <Button
              type="primary"
              onClick={handleGetPdf}
              style={{ width: "100%", marginTop: 20 }}
            >
              {isDownloadedPdf ? "Downloading Bill" : "Downloaded Bill"}
            </Button>
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export default Success;
