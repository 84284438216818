import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Pagination,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, useParams } from "react-router";
import { API } from "Redux/Apis";
import { ActivitiesActionsRequest } from "./ActivitiesActions";

export function getWithExpiry(key: string) {
  const itemStr = sessionStorage.getItem(key)
    ? sessionStorage.getItem(key)
    : null;
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item: any = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    sessionStorage.removeItem(key);
    return null;
  }
  return item.value;
}

var colorCode: any = window.localStorage.getItem("localColorCode");
const retrivedColorCodes = JSON.parse(colorCode);

interface ActivitiesProps {
  history: RouteComponentProps["history"];
}

const Activities: React.FC<ActivitiesProps> = ({ history }) => {
  const [startDate, setStartDate] = useState<string>(
    moment().subtract(30, "days").format().slice(0, 10)
  );
  const [endDate, setEndDate] = useState<string>(
    moment().format().slice(0, 10)
  );
  const [pageNo, setPageNo] = useState<number>(1);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const PaymentActivities = useSelector(
    (state: RootStateOrAny) => state.ActivitiesReducers
  );

  const paymentData = PaymentActivities.PaymentActivities?.data?.map(
    (dataObj: any) => {
      return {
        key: dataObj?.id,
        id: dataObj?.id,
        senderName: dataObj?.senderName,
        receiverName: dataObj?.receiverName,
        transactionAmount: dataObj?.transactionAmount,
        paymentMethod:
          dataObj?.paymentMethod === "CASHINHAND"
            ? "CASH IN HAND"
            : dataObj?.paymentMethod,
        transferFee: dataObj?.transferFee,
        paymentDate: dataObj?.paymentDate,
        paymentStatus: dataObj?.paymentStatus,
        actions: (
          <Space size="small">
            <Tooltip placement="top" title="View Activity Details">
              <Button
                type="primary"
                onClick={() => history.push(`/activities/${dataObj.paymentId}`)}
              >
                <EyeOutlined />
              </Button>
            </Tooltip>
            <Tooltip placement="top" title="Download Activity Details">
              <Button
                style={{ marginLeft: 4 }}
                type="primary"
                onClick={() => handleDownloadPdf(dataObj.paymentId)}
              >
                <DownloadOutlined />
              </Button>
            </Tooltip>
          </Space>
        ),
      };
    }
  );

  const handleDatePicker = (e: any, dateString: any) => {
    const startDate = dateString[0];
    const endDate = dateString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleApply = () => {
    dispatch(ActivitiesActionsRequest({ startDate, endDate, pageNo }));
  };

  const handlePageChange = (e: any) => {
    setPageNo(e);
  };

  const columns: any = [
    {
      title: "Sender Name",
      dataIndex: "senderName",
      key: "senderName",
    },
    {
      title: "Receiver Name",
      dataIndex: "receiverName",
      key: "receiverName",
      responsive: ["sm"] as Breakpoint[],
    },
    {
      title: "Transaction Amount",
      dataIndex: "transactionAmount",
      key: "transactionAmount",
      responsive: ["md"] as Breakpoint[],
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      responsive: ["md"] as Breakpoint[],
    },
    {
      title: "Transfer Fee",
      dataIndex: "transferFee",
      key: "transferFee",
      render: (transferFee: number) => {
        return <Typography.Text>{transferFee} AUD</Typography.Text>;
      },
      responsive: ["lg"] as Breakpoint[],
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      render: (text: any, record: any) => {
        return (
          <Typography.Text>{record.paymentDate.slice(0, 10)}</Typography.Text>
        );
      },
      responsive: ["xl"] as Breakpoint[],
    },
    {
      title: "Status",
      dataIndex: "paymentStatus",
      key: "status",
      responsive: ["sm"] as Breakpoint[],
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
    },
  ];
  let myDate = new Date().toString().split("+")[1];
  let myString = `+${myDate.slice(0, 2)}:${myDate.slice(2, 4)}`;
  const handleDownloadPdf = async (id: any) => {
    setPdfLoading(true);
    const reportPdfDoc = await API.get(
      `/transfer/payment/new-format/get-pdf/${id}/${myString}`,
      {
        headers: { Authorization: "Bearer " + getWithExpiry("ABC") },
        responseType: "blob",
      }
    );

    // console.log("The reportddsfcod",reportPdfDoc)
    if (reportPdfDoc?.status === 200) {
      setPdfLoading(false);
      const blob = new Blob([reportPdfDoc.data], {
        type: "application/pdf",
      });

      const computedFileName = `hamroremit_activity_file.pdf`;
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = computedFileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
  const handleGetActivities = () => {
    dispatch(ActivitiesActionsRequest({ startDate, endDate, pageNo }));
  };

  useEffect(() => {
    handleGetActivities();
  }, [dispatch, pageNo]);

  return (
    <div>
      <Card>
        <Typography.Title
          level={3}
          style={{ color: `${retrivedColorCodes.primaryColor}` }}
        >
          Payment Activities{" "}
        </Typography.Title>
        <Divider />
        <Space
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <DatePicker.RangePicker onChange={handleDatePicker} />
          <Button style={{ marginLeft: 20 }} onClick={handleApply}>
            Apply
          </Button>
        </Space>
        <Table
          style={{ marginTop: 30 }}
          pagination={false}
          columns={columns}
          loading={PaymentActivities?.loading}
          dataSource={paymentData}
        />
        <Pagination
          style={{ marginTop: 20 }}
          onChange={handlePageChange}
          pageSize={10}
          defaultCurrent={pageNo}
          total={PaymentActivities?.PaymentActivities?.totalData}
        />
        {pdfLoading && (
          <div className="downloading--loading">
            <div>Downloading ...</div>
          </div>
        )}
      </Card>
    </div>
  );
};

export default Activities;
