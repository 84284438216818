import React, { useEffect, useState } from "react";
import { API } from "Redux/Apis";
import moment from "moment";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Steps,
  Switch,
  Tabs,
  Typography,
} from "antd";
import Flage from "Assets/englishFlag.png";
import Nepaliflag from "Assets/nepaliFlag.png";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { IoIosRefreshCircle } from "react-icons/io";

import {
  BankDepositId,
  BankOrBranch,
  DeliveryNotification,
  PaymentMethod,
  PostPoliPayRequest,
  ReceivingAmount,
  RemittanceDepositId,
  TransactionAmount,
  UseLoyaltyPoints,
} from "./PoliPayRedux/PoliPayActions";
// import { useForm } from "antd/lib/form/Form";
import { SenderDetailsRequest } from "./SenderDetails/SenderDetailsActions";
import { useHistory } from "react-router";
import { SenderInformationRequest } from "./SenderInformation/SenderInformationActions";
import { Content } from "antd/lib/layout/layout";
import { GetBankListRequest } from "../BankList/BankListActions";
import { ReloadOutlined } from "@ant-design/icons";

interface SendMoneyProps {
  onSendMoney?: any;
  exchangeRates?: any;
}

export function getWithExpiry(key: string) {
  const itemStr = sessionStorage.getItem(key)
    ? sessionStorage.getItem(key)
    : null;
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item: any = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    sessionStorage.removeItem(key);
    return null;
  }
  return item.value;
}
const SendMoney: React.FC<SendMoneyProps> = ({
  onSendMoney,
  exchangeRates,
}) => {
  const [modal, contextHolder] = Modal.useModal();
  const history = useHistory();
  const [current, setCurrent] = useState<number>(0);
  const [aud, setAud] = useState<any>(1);
  const [npr, setNpr] = useState<any>(exchangeRates.toAmount);
  const [selectedBank, setSelectedBank] = useState<string>("");
  const [associatedBank, setAssociatedBank] = useState<string>("");
  const [bankId, setBankId] = useState<number>(0);
  const [receiverAmount, setReceiverAmount] = useState<number>(0);
  const [branchId, setBranchId] = useState<number>(0);
  const [selectedBranchName, setSelectedBranchName] = useState<string>("");
  const [sendType, setSendType] = useState<string>("bank_deposits");
  const [bankOrBranch, setbankOrBranch] = useState<string>("bank");
  // const [bankDetails, setBankDetails] = useState<any>([]);
  // const [recipientDetails, setRecipientDetails] = useState<any>([]);
  const [bankDetails, setBankDetails] = useState<any>({});
  const [recipientDetails, setRecipientDetails] = useState<any>();
  const [bankForm] = Form.useForm();

  //const [senderDetails, setSenderDetails] = useState<any>([]);
  const [navUrl, setNavUrl] = useState<string>("");
  const [transactionId, setTransactionId] = useState<number>(0);
  const [showIframe, setShowIframe] = useState<boolean>(false);
  const [showDeliveryNotification, setShowDeliveryNotification] =
    useState<boolean>(false);
  const [showAssociatedBank, setShowAssociatedBank] = useState<boolean>(false);
  const [showPdf, setShowPdf] = useState<boolean>(false);
  const [linkPdf, setLinkPdf] = useState<string>("");
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const [paymentId, setPaymentId] = useState<number>();
  const [delivery, setDelivery] = useState<boolean>(false);
  const [forLoyaltyPoints, setForLoyaltyPoints] = useState<boolean>(false);
  const [discount, setDiscount] = useState<boolean>(false);
  const [bankFeatured, setBankFeatured] = useState("featured");
  const [isBankDeposite, setIsBankDeposite] = useState<boolean>(true);
  const [locationId, setLocationId] = useState("");
  const [isBankSelected, setIsBankSelected] = useState<boolean>(false);
  const [zaiAccountRecord, setZaiAccountRecord] = useState<any>();
  const [listBank, setListBank] = useState([]);
  const dispatch = useDispatch();
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  const [isAddFund, setIsAddFund] = useState<boolean>(false);

  const [selectedPayment, setSelectedPayment] = useState("CASHINHAND");

  const SenderBankDetails = useSelector(
    (state: RootStateOrAny) => state.SenderDetailsReducers.SenderDetails
  );
  const LinkedBankAccounts = useSelector(
    (state: RootStateOrAny) =>
      state.LinkedBankAccountsReducers?.LinkedBankAccounts?.bankAccountList
        ?.data
  );

  const sendLocation = useSelector(
    (state: RootStateOrAny) =>
      state.SendLocationReducers.SendLocation.remittanceBranchList
  );

  useEffect(() => {
    if (selectedBranchName) {
      sendLocation?.map((item: any) => {
        if (item.branchName === selectedBranchName) {
          setBranchId(item.id);
        }
      });
    }
  }, [selectedBranchName]);

  const bankDetailsRef = React.createRef<any>();

  useEffect(() => {
    fetchZaiAccountDetail();
  }, []);

  const fetchZaiAccountDetail = async () => {
    await API.get("zai-payment/generate-token")
      .then((tokenRes) => {
        return API.get(`zai-payment/check-digital-account-balance`);
      })
      .then((res) => {
        setZaiAccountRecord(res?.data);
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const next = () => {
    setCurrent(current + 1);
    window.scrollTo(0, 0);
  };

  const prev = () => {
    setCurrent(current - 1);
    setShowDeliveryNotification(false);
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    bankDetailsRef?.current?.scrollIntoView({ behaviour: "smooth" });
  }, [bankDetailsRef]);

  useEffect(() => {
    setListBank(bankLists);
  }, []);

  const handleBankSearchChange = (e: any) => {
    const tempBank = e.target.value;
    const tempBankList = listBank.filter(
      (value: { bankName: any }) =>
        value?.bankName?.toLowerCase() === tempBank?.toLowerCase()
    );
  };

  const bankLists = useSelector(
    (state: RootStateOrAny) =>
      state.BankListReducers.BankLists.associatedBankList
  );
  const senderDetails = useSelector(
    (state: RootStateOrAny) => state.SenderInformationReducers.SenderInformation
  );
  const PoliPayReducers = useSelector(
    (state: RootStateOrAny) => state.PoliPayReducers
  );

  const ProfileDetails = useSelector(
    (state: RootStateOrAny) => state.ProfileReducers
  );
  const DashboardReducers = useSelector(
    (state: RootStateOrAny) => state.DashboardReducers
  );

  const [form2] = Form.useForm();
  useEffect(() => {
    form2.setFieldsValue(senderDetails);
  }, [senderDetails]);
  useEffect(() => {
    if (PoliPayReducers.PoliPayRes !== false) {
      setShowIframe(true);
      setNavUrl(PoliPayReducers.PoliPayRes);
    }
  }, [PoliPayReducers.PoliPayRes]);

  const handlePayment1 = async ({ paymentMethod, id, transactionId }: any) => {
    try {
      const response = await API.put("/transfer/payment", {
        notificationFlag: PoliPayReducers.notificationFlag,
        transactionDate: moment().format().slice(0, 19),
        transactionDescription: `${paymentMethod} ${bankOrBranch} ${id}`,
        paymentMethod: paymentMethod,
        transactionAmount: Number(aud),
        bankDepositId: id,
        transferFee: exchangeRates.transferFee,
        isLoyaltyPointRedeemed: PoliPayReducers?.useLoyaltyPoints
          ? true
          : false,
        receivingCurrency: "NPR",
        receivingAmount: npr,
        loyaltyPoints: "",
        equivalentLoyaltyAmount: "",
        transactionId: transactionId,
      });
      if (response.status === 200) {
        notification.success({ message: "Payment Successful!" });
        setShowPdf(true);
        setPdfLoading(true);
        setPaymentId(response.data.paymentId);
        setTimeout(function () {
          getPdf(response.data.paymentId);
        }, 500);
      } else
        notification.error({
          message: "Something Went Wrong, Please try again later.",
        });
    } catch (error: any) {}
  };

  let myDate = new Date().toString().split("+")[1];
  let myString = `+${myDate.slice(0, 2)}:${myDate.slice(2, 4)}`;

  const getPdf = async (id: any) => {
    try {
      const reportPdfDoc = await API.get(
        `/transfer/payment/new-format/get-pdf/${id}/${myString}`,
        {
          headers: { Authorization: "Bearer " + getWithExpiry("ABC") },
          responseType: "blob",
        }
      );
      if (reportPdfDoc?.status === 200) {
        setPdfLoading(false);
        const blob = new Blob([reportPdfDoc.data], {
          type: "application/pdf",
        });

        const computedFileName = `LaxmiPay_activity_file.pdf`;
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = computedFileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (error: any) {
      notification.error({ message: "Pdf Bill could not be generated!" });
    }
  };
  const handlePayment2 = async ({ paymentMethod, id }: any) => {
    try {
      const response = await API.put("/transfer/payment", {
        notificationFlag: PoliPayReducers.notificationFlag,
        transactionDate: moment().format().slice(0, 19),
        transactionDescription: `${paymentMethod} ${bankOrBranch} ${id}`,
        paymentMethod: paymentMethod,
        transactionAmount: Number(aud),
        remittanceDepositId: id,
        transferFee: exchangeRates.transferFee,
        isLoyaltyPointRedeemed: PoliPayReducers?.useLoyaltyPoints
          ? true
          : false,
        receivingCurrency: "NPR",
        receivingAmount: npr,
        loyaltyPoints: "",
        equivalentLoyaltyAmount: "",
      });
      if (response.status === 200) {
        notification.success({ message: "Payment Successful!" });
        setShowPdf(true);
        setPdfLoading(false);
        setPaymentId(response.data.paymentId);
        setTimeout(function () {
          getPdf(response.data.paymentId);
        }, 500);
      } else
        notification.error({
          message: "Something Went Wrong, Please try again later.",
        });
    } catch (error: any) {
      // console.log(error);
    }
  };

  const handleSendBankDetails = async ({
    paymentMethod = "",
    transactionId = "",
  }) => {
    try {
      const response = await API.post("/transfer/bank-deposit", {
        receiverAccountHolderName: bankDetails.accountHolderName,
        receiverAccountNumber: bankDetails.accountNumber,
        receiverBankBranch: bankDetails.branch,
        receiverBankName: selectedBank,
        senderEmail: senderDetails.email,
        recipientEmail: recipientDetails.recipientEmail,
        recipientFirstName: recipientDetails.recipientFirstName,
        recipientMiddleName: recipientDetails.recipientMiddleName,
        recipientLastName: recipientDetails.recipientLastName,
        recipientAddressLine1: recipientDetails.recipientAddressLine1,
        recipientMobileNo: recipientDetails.recipientMobileNumber,
        reasonForSending: recipientDetails.reasonForSending,
        senderCurrency: "AUD",
        senderAmount: Number(aud),
        receiverCurrency: "NPR",
        receiverAmount: npr,
        recipientZone: recipientDetails.recipientZone,
        recipientCity: recipientDetails.recipientCity,
        associatedBankId: bankId,
        senderSuburb: senderDetails.suburb,
        senderState: senderDetails.state,
        senderPostCode: senderDetails.postcode,
        senderMobileNumber: senderDetails.phoneNumber,
        senderFirstName: senderDetails.customerFirstName,
        senderMiddleName: senderDetails.customerMiddleName,
        senderLastName: senderDetails.customerLastName,
        senderAddressLine1: senderDetails.streetAddress,
        exchangeRate: exchangeRates.conversionRate,
        locationId: isBankDeposite ? locationId : "",
      });

      if (paymentMethod === "CASHINHAND" || paymentMethod === "DIGITALWALLET") {
        handlePayment1({
          paymentMethod: paymentMethod,
          id: response.data.bankDepositId,
          transactionId: transactionId,
        });
      } else {
        handlePoliPay();
      }
      if (response.status === 200) {
        dispatch(BankDepositId(response.data.bankDepositId));
      }
    } catch (error: any) {
      // console.log(error);
      notification.error({
        message: "FAILED!",
        description: error?.response?.data?.message,
      });
    }
  };

  const handleSendRemittanceDetails = async ({ paymentMethod }: any) => {
    try {
      const response = await API.post("/transfer/remittance", {
        senderAddressLine1: senderDetails.streetAddress,
        senderEmail: senderDetails.email,
        senderState: senderDetails.state,
        senderPostCode: senderDetails.postcode,
        senderMobileNumber: senderDetails.phoneNumber,
        senderFirstName: senderDetails.customerFirstName,
        senderMiddleName: senderDetails.customerMiddleName,
        senderLastName: senderDetails.customerLastName,
        senderCurrency: "AUD",
        senderAmount: Number(aud),
        recipientEmail: recipientDetails.recipientEmail,
        recipientFirstName: recipientDetails.recipientFirstName,
        recipientMiddleName: recipientDetails.recipientMiddleName,
        recipientLastName: recipientDetails.recipientLastName,
        recipientAddressLine1: recipientDetails.recipientAddressLine1,
        recipientMobileNo: recipientDetails.recipientMobileNumber,
        reasonForSending: recipientDetails.reasonForSending,
        receiverCurrency: "NPR",
        receiverAmount: npr,
        recipientZone: recipientDetails.recipientZone,
        recipientCity: recipientDetails.recipientCity,
        remittanceBranch: branchId,
        senderSuburb: senderDetails.suburb,
        exchangeRate: exchangeRates.conversionRate,
      });
      if (paymentMethod === "CASHINHAND") {
        handlePayment2({
          paymentMethod: paymentMethod,
          id: response.data.remittanceDepositId,
        });
      } else {
        handlePoliPay();
      }
      if (response.status === 200) {
        dispatch(RemittanceDepositId(response.data.remittanceDepositId));
      }
    } catch (error: any) {
      // console.log(error);
      notification.error({
        message: "FAILED!",
        description: error?.response?.data?.message,
      });
    }
  };

  const handleFeaturedChange = (value: string) => {
    setBankFeatured(value);
    bankForm.setFieldsValue({
      bankName: "",
    });
  };

  const handlePoliPay = async () => {
    let preAmount = Number(aud) + Number(exchangeRates.transferFee);
    let discountAmount = Number(exchangeRates?.convertedLoyaltyAmount);
    let tempAmout = PoliPayReducers?.useLoyaltyPoints
      ? preAmount - discountAmount
      : preAmount;
    dispatch(
      PostPoliPayRequest({
        Amount: tempAmout,
        CurrencyCode: "AUD",
        MerchantReference: "CustomerRef" + senderDetails.customerId,
        MerchantHomepageURL: "https://txn.apac.paywithpoli.com",

        // SuccessURL: "http://localhost:3000/success",
        // FailureURL: "http://localhost:3000/error",
        // CancellationURL: "http://localhost:3000/",
        // NotificationURL: "http://localhost:3000/",

        // SuccessURL: "https://laxmipay.remittancemanagementsystem.com/success",
        // FailureURL: "https://laxmipay.remittancemanagementsystem.com/error",
        // CancellationURL: "https://laxmipay.remittancemanagementsystem.com/",
        // NotificationURL: "https://laxmipay.remittancemanagementsystem.com/",

        SuccessURL: "https://hrclient.remittancemanagementsystem.com/success",
        FailureURL: "https://hrclient.remittancemanagementsystem.com/error",
        CancellationURL: "https://hrclient.remittancemanagementsystem.com/",
        NotificationURL: "https://hrclient.remittancemanagementsystem.com/",
      })
    );
  };

  const openNotification = (desc: string) => {
    notification.error({
      message: "Failed",
      description: desc,
      onClick: () => {},
    });
  };

  const openSuccessNotification = (desc: string) => {
    notification.success({
      message: "Success",
      description: desc,
      onClick: () => {},
    });
  };

  const handleTransferZaiFund = () => {
    bankOrBranch === "bank" && handleLinkCustomeAndBank();
    bankOrBranch === "branch" && handleLinkCustomerAndBranch();

    const totalAmount = Number(aud) + Number(exchangeRates.transferFee);
    totalAmount < Number(zaiAccountRecord?.balance) &&
      setIsPaymentLoading(true);

    totalAmount > Number(zaiAccountRecord?.balance)
      ? openNotification(
          "Avaiable balance is not enough ,Please transfer the fund in your wallet"
        )
      : API.get("zai-payment/generate-token")
          .then((tokenRes) => {
            return API.post("zai-payment/create-payment-item", {
              amount: totalAmount * 100,
              buyerId: zaiAccountRecord?.buyerId,
              sellerId: "f30ca38242e5744070af3166a6e4efaf",
              name: "transfer amount",
              taxInvoice: false,
            });
          })
          .then((res) => {
            return API.post("zai-payment/make-payment-user", {
              itemId: res?.data?.id,
              payType: "your_pay_type_value",
              accountId: zaiAccountRecord?.walletId,
              deviceId: "4444",
              ipAddress: "192.168.4.1",
              cvv: null,
            });
          })
          .then((resSuccess) => {
            openSuccessNotification(resSuccess?.data?.message);
            handleSendBankDetails({ paymentMethod: "DIGITALWALLET" });
          })
          .catch((err) => {
            openNotification(err?.response?.data?.message);
          })
          .finally(() => {
            setIsPaymentLoading(false);
          });
  };

  const handleBankDetails = (values: any) => {
    dispatch(SenderDetailsRequest(values));
    setShowRecipientsDetails(false);
    setBankDetails(values);
    setCurrent(current + 1);
    setShowAssociatedBank(false);
    window.scrollTo(0, 0);
  };

  const handleRecipientDetails = (values: any) => {
    window.scrollTo(0, 0);
    const valuesToSend = {
      ...values,
      province: values.zone,
      addressLine1: values.addressLine,
      phoneNumber: values.mobileNumber,
    };
    setShowDeliveryNotification(true);

    setRecipientDetails(valuesToSend);
    setShowRecipientsDetails(false);
  };

  const handleLinkCustomeAndBank = () => {
    API.post("/customer/link-bank-account", {
      firstName: recipientDetails.recipientFirstName,
      middleName: recipientDetails.recipientMiddleName,
      lastName: recipientDetails.recipientLastName,
      addressLineOne: recipientDetails.recipientAddressLine1,
      city: recipientDetails.recipientCity,
      mobileNumber: recipientDetails.recipientMobileNumber,
      emailAddress: recipientDetails.recipientEmail,
      zone: recipientDetails.recipientZone,
      accountHolderName: bankDetails.accountHolderName,
      accountNumber: bankDetails.accountNumber,
      bank: bankDetails.bankName,
      branch: bankDetails.branch,
      reasonForSending: recipientDetails.reasonForSending,
      bankId: bankId,
      locationId: locationId,
    })
      .then((res) => {})
      .catch((err) => {});
  };

  const handleLinkCustomerAndBranch = () => {
    API.post("/recipient/add", {
      recipientFirstName: recipientDetails.recipientFirstName,
      recipientMiddleName: recipientDetails.recipientMiddleName,
      recipientLastName: recipientDetails.recipientLastName,
      addresslineone: recipientDetails.recipientAddressLine1,
      recipientCity: recipientDetails.recipientCity,
      recipientZone: recipientDetails.recipientZone,
      recipientMobileNumber: recipientDetails.recipientMobileNumber,
      recipientEmail: recipientDetails.recipientEmail,
      reasonForSending: recipientDetails.reasonForSending,
    })
      .then((res) => {})
      .catch((err) => {});
  };

  const handleSendMoney = async (values: any) => {
    if (
      PoliPayReducers?.useLoyaltyPoints &&
      values?.paymentMethod === "POLIPAY"
    ) {
      alert("Loyalty point is being used !!!");
    }

    bankOrBranch === "bank" && handleLinkCustomeAndBank();
    bankOrBranch === "branch" && handleLinkCustomerAndBranch();
    dispatch(TransactionAmount(Number(aud)));
    dispatch(ReceivingAmount(npr));
    dispatch(BankOrBranch(bankOrBranch));
    dispatch(PaymentMethod(values));
    if (bankOrBranch === "bank") {
      handleSendBankDetails(values);
    } else {
      handleSendRemittanceDetails(values);
    }
  };
  const handleCancel = () => {
    setShowIframe(false);
  };
  const closeDelivery = () => {
    dispatch(DeliveryNotification(false));
    setDelivery(false);
    setShowDeliveryNotification(false);
    setCurrent(current + 1);
  };
  const acceptDelivery = () => {
    dispatch(DeliveryNotification(true));
    setDelivery(true);
    setCurrent(current + 1);
  };
  const handleSelectBank = (value: any, key: any) => {
    setIsBankSelected(false);
    setBankId(Number(key.key));
    setSelectedBank(value);
    setShowAssociatedBank(true);
    const selectedAssociateBank = LinkedBankAccounts?.filter(
      (bank: any) => bank.bankName === value
    );

    setLocationId(selectedAssociateBank[0].locationId);
    bankForm.setFieldsValue({
      bankName: selectedAssociateBank[0].bankName,
      accountHolderName: selectedAssociateBank[0].accountHolderName,
      accountNumber: selectedAssociateBank[0].accountNumber,
      branch: selectedAssociateBank[0].branch,
      reasonForSending: selectedAssociateBank[0].reasonForSending,
    });

    // setBankDetails(selectedAssociateBank[0]);
    // form1.setFieldsValue({
    //   ...selectedAssociateBank[0],
    //   recipientFirstName: selectedAssociateBank[0].firstName,
    //   recipientMiddleName: selectedAssociateBank[0].middleName,
    //   recipientLastName: selectedAssociateBank[0].lastName,
    //   recipientAddressLine1: selectedAssociateBank[0].addressLine,
    //   recipientCity: selectedAssociateBank[0].city,
    //   recipientZone: selectedAssociateBank[0].zone,
    //   recipientMobileNumber: selectedAssociateBank[0].mobileNumber,
    //   recipientEmail: selectedAssociateBank[0].emailAddress,
    //   reasonForSending: selectedAssociateBank[0].reasonForSending,
    // });
    // setBankDetails(selectedAssociateBank[0]);
    setBankDetails(selectedAssociateBank[0]);

    form1.setFieldsValue({
      // ...selectedAssociateBank[0],

      recipientFirstName: selectedAssociateBank[0].firstName,
      recipientMiddleName: selectedAssociateBank[0].middleName,
      recipientLastName: selectedAssociateBank[0].lastName,
      recipientAddressLine1: selectedAssociateBank[0].addressLine,
      recipientCity: selectedAssociateBank[0].city,
      recipientZone: selectedAssociateBank[0].zone,
      recipientMobileNumber: selectedAssociateBank[0].mobileNumber,
      recipientEmail: selectedAssociateBank[0].emailAddress,
      reasonForSending: selectedAssociateBank[0].reasonForSending,
    });
  };
  const [showRecipientDetails, setShowRecipientsDetails] =
    useState<boolean>(false);

  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(bankDetails);
  }, [bankDetails]);

  const handleSelectRecipient = (key: any) => {
    setShowRecipientsDetails(true);
    const selectedRecipientDetails =
      SavedRecipientsReducers?.SavedRecipients?.data?.filter(
        (recipient: any) => recipient.recipientFirstName === key
      );

    setRecipientDetails(selectedRecipientDetails[0]);
    setRecipientDetails((prev: any) => {
      return {
        ...prev,
        recipientFirstName: selectedRecipientDetails[0]?.recipientFirstName,
        recipientMiddleName: selectedRecipientDetails[0]?.recipientMiddleName,
        recipientLastName: selectedRecipientDetails[0]?.recipientLastName,
        recipientAddressLine1:
          selectedRecipientDetails[0]?.recipientAddressLine1,
        recipientCity: selectedRecipientDetails[0]?.recipientCity,
        recipientZone: selectedRecipientDetails[0]?.recipientZone,
        recipientMobileNumber:
          selectedRecipientDetails[0]?.recipientMobileNumber,
        recipientEmail: selectedRecipientDetails[0]?.recipientEmail,
        reasonForSending: selectedRecipientDetails[0]?.reasonForSending,
      };
    });
  };
  useEffect(() => {
    form1.setFieldsValue(recipientDetails);
  }, [recipientDetails]);

  const handleSenderInformation = (values: any) => {
    dispatch(SenderInformationRequest(values));
    setCurrent(current + 1);
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    dispatch(GetBankListRequest());
  }, []);

  useEffect(() => {
    const senderDetails = async () => {
      try {
        const response = await API.get("/customer/get-profile");
        if (response.status === 200) {
          dispatch(SenderInformationRequest(response.data));
        }
      } catch (error) {}
    };
    senderDetails();
  }, []);

  const SavedRecipientsReducers = useSelector(
    (state: RootStateOrAny) => state.SavedRecipientsReducers
  );
  return (
    <>
      <Modal
        visible={showPdf}
        onOk={() => {
          setShowPdf(false);
          onSendMoney(false);
          history.push("/activities");
        }}
        onCancel={() => {
          setShowPdf(false);
          history.push("/activities");
        }}
      >
        <Alert message="Successfully Paid!" type="success" showIcon />
        <Alert
          message="You can view the details of payment on Activities Tab!"
          type="info"
          showIcon
          style={{ marginTop: 20 }}
        />
        <Button
          loading={pdfLoading}
          type="primary"
          style={{ width: "100%", marginTop: 20 }}
        >
          {pdfLoading ? "Downloading Bill" : "Downloaded Bill"}
        </Button>
      </Modal>

      <Modal
        onCancel={handleCancel}
        visible={showIframe}
        footer={null}
        bodyStyle={{ height: 650, padding: 35 }}
      >
        <iframe
          src={navUrl}
          //  frameborder='0'
          style={{ height: "100%", width: "100%", margin: 0, padding: 0 }}
        ></iframe>
      </Modal>
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {current === 0 && (
            <Button onClick={() => onSendMoney(false)}> Go Back</Button>
          )}
          <h4 style={{ fontSize: 18, color: "#1890ff", marginLeft: 30 }}>
            {current === 0
              ? "Send Money"
              : current === 1
              ? "Delivery Method"
              : current === 2
              ? "Recipient Details"
              : "Payment"}
          </h4>
        </div>
        <Divider />
        <Row>
          <Col span={24}>
            <Steps current={current}>
              <Steps.Step key="1" title="Amount" />
              <Steps.Step key="2" title="Payout" />
              <Steps.Step key="3" title="Recipient" />
              <Steps.Step key="4" title="Payment" />
            </Steps>
            {current === 0 && (
              <div
                style={{
                  marginTop: 30,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <Avatar src={Flage} style={{ width: 20, height: "auto" }} />{" "}
                  AUD:
                  <Input
                    min={0}
                    type="number"
                    value={aud}
                    style={{ width: 300, margin: "auto" }}
                    onChange={(e: any) => {
                      if (e.target.value >= 0) {
                        setAud(e.target.value);
                        setNpr(e.target.value * exchangeRates.toAmount);
                      }
                    }}
                  />
                </div>
                <label
                  style={{
                    fontSize: 12,
                    backgroundColor: "tomato",
                    color: "#fff",
                    marginTop: 10,
                    padding: 5,
                  }}
                >
                  Enter your amount here
                </label>
                <div style={{ marginTop: 30 }}>
                  <Avatar
                    src={Nepaliflag}
                    style={{ width: 25, height: "auto" }}
                  />{" "}
                  NPR:{" "}
                  <Input
                    type="number"
                    disabled
                    value={npr?.toFixed(2)}
                    style={{ width: 300, margin: "auto", color: "#000" }}
                  />
                </div>
                <Card style={{ marginTop: 30 }}>
                  <div
                    style={{
                      fontSize: 18,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography.Text>
                      Exchange Rate: {exchangeRates?.conversionRate}
                    </Typography.Text>
                    <Typography.Text>
                      Offer: {exchangeRates?.commissionOffer} (
                      {exchangeRates?.discountAmount}%) off
                    </Typography.Text>
                    <Typography.Text>
                      Fee:{" "}
                      <span
                        style={{
                          textDecorationLine: "line-through",
                          color: "green",
                        }}
                      >
                        {exchangeRates.defaultCommission?.toFixed(2)} AUD
                      </span>{" "}
                      {exchangeRates.transferFee?.toFixed(2)} AUD
                    </Typography.Text>
                    <Typography.Text style={{ color: "#000" }}>
                      Total Amount:{" "}
                      {(
                        Number(aud) + Number(exchangeRates.transferFee)
                      ).toFixed(2)}{" "}
                      AUD
                    </Typography.Text>
                  </div>
                </Card>
              </div>
            )}
            {current === 1 && (
              <div style={{ marginTop: 30 }}>
                <Tabs
                  defaultActiveKey="bank_deposits"
                  onChange={(e: any) => {
                    setSendType(e.key);
                    setbankOrBranch(e === "bank_deposits" ? "bank" : "branch");
                    setIsBankDeposite(e === "bank_deposits" ? true : false);
                  }}
                >
                  <Tabs.TabPane tab="Bank Deposits" key="bank_deposits">
                    <Row align="middle">
                      <Col span={24}>
                        <Space>
                          <Typography.Title
                            level={4}
                            style={{ margin: 0, color: "#D31413" }}
                          >
                            Select Bank OR
                          </Typography.Title>
                          <Select
                            placeholder="Select Linked Bank Account HolderName"
                            onSelect={handleSelectBank}
                          >
                            {LinkedBankAccounts?.map(
                              (bank: any) =>
                                bank.accociatedBankActiveStatus && (
                                  <Select.Option
                                    key={bank.associatedBankId}
                                    value={bank.bankName}
                                  >
                                    {bank.bankName},{bank.accountHolderName}
                                    {/* {bank.bankName},{" "}
                                    <span style={{ color: "#f4822a" }}>
                                      {bank.accountHolderName}
                                    </span> */}
                                  </Select.Option>
                                )
                            )}
                          </Select>
                          {/* <Input
                            onChange={handleBankSearchChange}
                            placeholder="Search Bank"
                          /> */}
                          {/* <Select
                            onSelect={handleFeaturedChange}
                            value={bankFeatured}
                          >
                            <Select.Option key={1} value="featured">
                              <span>Featured</span>
                            </Select.Option>
                            <Select.Option key={2} value="non-featured">
                              <span>Non Featured</span>
                            </Select.Option>
                          </Select> */}
                        </Space>
                      </Col>
                      {/* <div>
                        <p>Featured</p>
                        <p>Non Featured</p>
                      </div> */}
                      {/* {bankFeatured === "featured"
                        ? bankLists?.map((bank: any) => {
                            if (bank.isBankFeatured) {
                              return (
                                <Col
                                  onClick={() => {
                                    setSelectedBank(bank.bankName);
                                    setBankId(bank.id);
                                    setbankOrBranch("bank");
                                  }}
                                  style={{
                                    display: "flex",
                                    // justifyContent: "flex-sta",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    height: "12rem",
                                    width: "12rem",
                                    border:
                                      selectedBank === bank.bankName
                                        ? "1px solid #000"
                                        : "",
                                    cursor: "pointer",
                                  }}
                                  sm={12}
                                  md={8}
                                  lg={8}
                                  xl={6}
                                  xxl={6}
                                  key={bank.id}
                                >
                                  <Space
                                    direction="vertical"
                                    className="flex-col-center"
                                  >
                                    <img
                                      style={{
                                        width: "10rem",
                                        height: "8rem",
                                        objectFit: "cover",
                                      }}
                                      onClick={() => {
                                        setLocationId(bank.locationId);
                                        bankForm.setFieldsValue({
                                          bankName: bank.bankName,
                                        });
                                      }}
                                      src={bank.bankLogo}
                                      alt="hello world"
                                      style={{
                                        height: "auto",
                                        width: 200,
                                        padding: "0.5rem",
                                      }}
                                    />
                                  </Space>
                                </Col>
                              );
                            }
                          })
                        : bankLists?.map((bank: any) => {
                            if (!bank.isBankFeatured) {
                              return (
                                <Col
                                  onClick={() => {
                                    setSelectedBank(bank.bankName);
                                    setBankId(bank.id);
                                    setbankOrBranch("bank");
                                  }}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    height: 250,
                                    marginTop: 40,
                                    border:
                                      selectedBank === bank.bankName
                                        ? "1px solid #000"
                                        : "",
                                    cursor: "pointer",
                                  }}
                                  sm={12}
                                  md={8}
                                  lg={8}
                                  xl={6}
                                  xxl={6}
                                  key={bank.id}
                                >
                                  <Space
                                    direction="vertical"
                                    className="flex-col-center"
                                  >
                                    <img
                                      onClick={() => {
                                        setLocationId(bank.locationId);
                                        bankForm.setFieldsValue({
                                          bankName: bank.bankName,
                                        });
                                      }}
                                      src={bank.bankLogo}
                                      alt="hello world"
                                      style={{ height: "auto", width: 200 }}
                                    />
                                  </Space>
                                </Col>
                              );
                            }
                          })} */}
                      {bankFeatured === "featured" && (
                        <div className="bank--card--container">
                          {listBank?.map((bank: any) => {
                            if (bank.isBankFeatured) {
                              return (
                                <div
                                  onClick={() => {
                                    setSelectedBank(bank.bankName);
                                    setBankId(bank.id);
                                    setbankOrBranch("bank");
                                  }}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    background: "#f5f5f5",
                                    padding: "0.5rem",
                                    borderRadius: "0.5rem",
                                    border:
                                      selectedBank === bank.bankName &&
                                      isBankSelected
                                        ? "2px solid #dc992b"
                                        : "",
                                    cursor: "pointer",
                                  }}
                                  key={bank.id}
                                >
                                  <Space
                                    direction="vertical"
                                    className="flex-col-center"
                                  >
                                    <img
                                      className="bank--image"
                                      onClick={() => {
                                        setLocationId(bank.locationId);
                                        setIsBankSelected(true);
                                        bankForm.setFieldsValue({
                                          bankName: bank.bankName,
                                          accountHolderName: "",
                                          accountNumber: "",
                                          branch: "",
                                          reasonForSending: "",
                                        });

                                        form1.setFieldsValue({
                                          recipientFirstName: "",
                                          recipientMiddleName: "",
                                          recipientLastName: "",
                                          recipientAddressLine1: "",
                                          recipientCity: "",
                                          recipientZone: "",
                                          recipientMobileNumber: "",
                                          recipientEmail: "",
                                          reasonForSending: "",
                                        });
                                      }}
                                      src={bank.bankLogo}
                                      alt="hello world"
                                    />
                                  </Space>
                                </div>
                              );
                            }
                          })}
                        </div>
                      )}
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Remittance (Pickup)" key="remittance">
                    <Row>
                      <Col span={24}>
                        <Typography.Title level={3}>
                          Where would you like to send money?
                        </Typography.Title>
                        <Select
                          showSearch
                          style={{ width: 300 }}
                          placeholder="Search or Select Branch"
                          optionFilterProp="children"
                          onChange={(e: any) => {
                            setSelectedBranchName(e);
                            setCurrent(current + 1);
                          }}
                          filterOption={(input: any, option: any) =>
                            option.props.children[0]
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {/* <Select.Option key="any" value="">
                            Any
                          </Select.Option> */}
                          {sendLocation?.map(
                            (location: any) =>
                              location.isActive && (
                                <Select.Option
                                  key={location.branchName}
                                  value={location.branchName}
                                >
                                  {location.branchName},{" "}
                                  {location.branchLocation}
                                </Select.Option>
                              )
                          )}
                        </Select>
                        {/* <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {sendLocation?.map(
                            (location: any) =>
                              location.isActive && (
                                <Typography.Text
                                  onClick={() => {
                                    setSelectedBranchName(location.branchName);
                                    setBranchId(location.id);
                                    setCurrent(current + 1);
                                    setbankOrBranch("branch");
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {location.branchName},{" "}
                                  {location.branchLocation}
                                </Typography.Text>
                              )
                          )}
                        </div> */}
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                </Tabs>
              </div>
            )}
            {current === 1 &&
              selectedBank !== "" &&
              bankOrBranch === "bank" && (
                <div ref={bankDetailsRef} style={{ marginTop: 20 }}>
                  <Card>
                    <Space className="displat-space-between">
                      <Typography.Title
                        level={3}
                        style={{ margin: 0, color: "#D31413" }}
                      >
                        Bank Details
                      </Typography.Title>
                    </Space>
                    <Form
                      form={bankForm}
                      onFinish={handleBankDetails}
                      layout="vertical"
                    >
                      {/* <Form.Item label="Bank Name">
                        <Input
                          value={selectedBank}
                          disabled
                          size="large"
                          style={{ color: "#000" }}
                        />
                      </Form.Item> */}
                      <Form.Item label="Bank Name" name="bankName">
                        <Input
                          placeholder="Bank Name"
                          size="large"
                          style={{ color: "#000" }}
                          readOnly
                        />
                      </Form.Item>
                      <Form.Item
                        label="Account Holder's Name"
                        name="accountHolderName"
                        rules={[
                          {
                            required: true,
                            message: "Account Holder's Name is required!",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Account Holder's Name"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Account Number"
                        name="accountNumber"
                        rules={[
                          {
                            required: true,
                            message: "Account Number is required!",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          size="large"
                          placeholder="Account Number"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Branch"
                        name="branch"
                        // rules={[
                        //   { required: true, message: "Branch is required!" },
                        // ]}
                      >
                        <Input size="large" placeholder="Branch Name" />
                      </Form.Item>
                      <Form.Item
                        label="Reason For Sending"
                        name="reasonForSending"
                        rules={[
                          {
                            required: true,
                            message: "Reason For Sending is required!",
                          },
                        ]}
                      >
                        {/* <Input size="large" placeholder="Reason for Sending" /> */}
                        <Select placeholder="Select Reason for Sending">
                          <Select.Option value="personal">
                            Personal
                          </Select.Option>
                          <Select.Option value="family">Family</Select.Option>
                          <Select.Option value="business">
                            Business
                          </Select.Option>
                          <Select.Option value="others">Others</Select.Option>
                        </Select>
                      </Form.Item>
                      <Button type="primary" htmlType="submit">
                        Submit Bank Details and Continue
                      </Button>
                    </Form>
                  </Card>
                </div>
              )}
            {current === 2 && (
              <div style={{ marginTop: 20 }}>
                <Card>
                  <Space>
                    <Typography.Title
                      level={4}
                      style={{ color: "#D31413", margin: 0 }}
                    >
                      Recipient Details {bankOrBranch === "branch" && "OR"}
                    </Typography.Title>
                    {bankOrBranch === "branch" && (
                      <Select
                        placeholder="Select Saved Recipient Account"
                        onSelect={handleSelectRecipient}
                      >
                        {SavedRecipientsReducers?.SavedRecipients?.data.map(
                          (recipient: any) => {
                            return (
                              <Select.Option
                                key={recipient.recipientId}
                                value={recipient.recipientFirstName}
                              >
                                {recipient.recipientFirstName}
                                {recipient.recipientLastName}
                              </Select.Option>
                            );
                          }
                        )}
                      </Select>
                    )}
                  </Space>
                  <Form
                    form={form1}
                    onFinish={handleRecipientDetails}
                    layout="vertical"
                    style={{ marginTop: "1rem" }}
                  >
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        gap: "1rem",
                      }}
                    >
                      <Form.Item
                        label="First Name"
                        name="recipientFirstName"
                        rules={[
                          {
                            required: true,
                            message: "First Name is Required!",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="First Name" />
                      </Form.Item>
                      <Form.Item label="Middle Name" name="recipientMiddleName">
                        <Input size="large" placeholder="Middle Name" />
                      </Form.Item>
                      <Form.Item
                        label="Last Name"
                        name="recipientLastName"
                        rules={[
                          { required: true, message: "Last Name is Required!" },
                        ]}
                      >
                        <Input size="large" placeholder="Last Name" />
                      </Form.Item>
                    </div>{" "}
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "1rem",
                      }}
                    >
                      <Form.Item
                        label="Mobile Number"
                        name="recipientMobileNumber"
                        rules={[
                          { min: 9, message: "Min length is 9!" },
                          { max: 15, message: "Max length is 15!" },
                          {
                            required: true,
                            message: "Phone Number is Required!",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          size="large"
                          placeholder="Mobile Number"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Email Address"
                        name="recipientEmail"
                        //rules={[
                        //  { type: "email", message: "Please use valid email!" },
                        //  { required: true, message: "Email is Required!" },
                        //]}
                      >
                        <Input
                          type="email"
                          size="large"
                          placeholder="Email Address"
                        />
                      </Form.Item>{" "}
                    </div>
                    <Typography.Text>
                      Please ensure your recipient's name entered here matches
                      the details on their government issued ID as they will
                      need to present it during collections. Any incorrect names
                      cause a delay when collecting funds.
                    </Typography.Text>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "1rem",
                        marginTop: "1rem",
                      }}
                    >
                      <Form.Item
                        label="Address Line 1"
                        name="recipientAddressLine1"
                        rules={[
                          {
                            required: true,
                            message: "Address Line 1 is Required!",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Address Line 1" />
                      </Form.Item>
                      <Form.Item
                        label="City"
                        name="recipientCity"
                        rules={[
                          { required: true, message: "City is Required!" },
                        ]}
                      >
                        <Input size="large" placeholder="City" />
                      </Form.Item>
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "1rem",
                      }}
                    >
                      <Form.Item
                        label="Select Province"
                        name="recipientZone"
                        // rules={[
                        //   { required: true, message: "Province is Required!" },
                        // ]}
                      >
                        <Select size="large" placeholder="Select Province">
                          <Select.Option value="1">Province 1</Select.Option>
                          <Select.Option value="2">Province 2</Select.Option>
                          <Select.Option value="3">Province 3</Select.Option>
                          <Select.Option value="4">Province 4</Select.Option>
                          <Select.Option value="5">Province 5</Select.Option>
                          <Select.Option value="6">Province 6</Select.Option>
                          <Select.Option value="7">Province 7</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Reason For Sending"
                        name="reasonForSending"
                        rules={[
                          {
                            required: true,
                            message: "Reason For Sending is Required!",
                          },
                        ]}
                      >
                        {/* <Input size="large" placeholder="Reason For Sending" /> */}
                        <Select
                          size="large"
                          placeholder="Select Reason for Sending"
                        >
                          <Select.Option value="personal">
                            Personal
                          </Select.Option>
                          <Select.Option value="family">Family</Select.Option>
                          <Select.Option value="business">
                            Business
                          </Select.Option>
                          <Select.Option value="others">Others</Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <Alert
                      message="Incorrect Details will cause delays to transactions."
                      description="By Submitting Recipient Details and continuing with this payment you are confirming that, to the best of your knowledge, the details you are providing are correct."
                      type="info"
                      showIcon
                    />
                    <Button
                      style={{ marginTop: "1rem" }}
                      type="primary"
                      htmlType="submit"
                    >
                      Submit Recipient Details
                    </Button>
                  </Form>
                  <Modal
                    cancelText="No"
                    okText="Yes"
                    onOk={acceptDelivery}
                    onCancel={closeDelivery}
                    visible={showDeliveryNotification}
                    title="Delivery Notification"
                  >
                    Would you like us to notify when money is ready?
                  </Modal>
                </Card>
              </div>
            )}
            {current === 3 && (
              <Card style={{ marginTop: 30 }}>
                <Typography.Title
                  level={4}
                  style={{ color: "#D31413", margin: 0 }}
                >
                  Sender Details
                </Typography.Title>
                <Form
                  form={form2}
                  onFinish={handleSenderInformation}
                  layout="vertical"
                  style={{ marginTop: "1rem" }}
                >
                  {" "}
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                      gap: "1rem",
                      marginTop: "1rem",
                    }}
                  >
                    <Form.Item
                      name="customerFirstName"
                      label="First Name"
                      rules={[
                        { required: true, message: "First Name is Required!" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name="customerMiddleName" label="Middle Name">
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="customerLastName"
                      label="Last Name"
                      rules={[
                        { required: true, message: "Last Name is Required!" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "1rem",
                    }}
                  >
                    <Form.Item
                      name="email"
                      label="Email"
                      //rules={[
                      //  { type: "email", message: "Please enter valid Email Address." },
                      //  { required: true, message: "Please enter a valid Email Address" },
                      //]}
                    >
                      <Input type="email" />
                    </Form.Item>
                    <Form.Item
                      name="phoneNumber"
                      label="Mobile Number"
                      rules={[
                        { min: 9, message: "Min length is 9." },
                        { max: 15, message: "Max length is 15." },
                        {
                          required: true,
                          message: "Please enter a valid mobile number.",
                        },
                      ]}
                    >
                      <Input type="number" />
                    </Form.Item>
                  </div>
                  <Form.Item>
                    Please ensure your sender's name entered here matches the
                    details on their government issued ID as they will need to
                    rpesent it during collections. Any incorrect names cause a
                    delay when collecting funds.
                  </Form.Item>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "1rem",
                      marginTop: "1rem",
                    }}
                  >
                    <Form.Item
                      name="streetAddress"
                      label="Street Address"
                      rules={[
                        {
                          required: true,
                          message: "Street Address is Required!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="suburb"
                      label="Suburb"
                      rules={[
                        { required: true, message: "Suburb is Required!" },
                      ]}
                    >
                      <Input />
                    </Form.Item>{" "}
                  </div>{" "}
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "1rem",
                    }}
                  >
                    <Form.Item
                      name="state"
                      label="State"
                      rules={[
                        { required: true, message: "State is Required!" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name="postcode"
                      label="Postcode"
                      rules={[
                        { required: true, message: "Postcode is Required!" },
                      ]}
                    >
                      <Input />
                    </Form.Item>{" "}
                  </div>
                  <Button type="primary" htmlType="submit">
                    Continue
                  </Button>
                </Form>
              </Card>
            )}
            {current === 4 && (
              <div style={{ marginTop: 30 }}>
                <Card>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "1rem",
                      width: "100%",
                    }}
                  >
                    <Form
                      initialValues={{
                        paymentMethod: "CASHINHAND",
                      }}
                      onFinish={handleSendMoney}
                      layout="vertical"
                    >
                      <Form.Item
                        label="Select Method of Sending Money"
                        name="paymentMethod"
                        rules={[
                          {
                            required: true,
                            message: "Please choose payment method!",
                          },
                        ]}
                      >
                        <Radio.Group
                          style={{ display: "flex", flexDirection: "column" }}
                          onChange={(e: any) => {
                            setForLoyaltyPoints(
                              e.target.value === "CASHINHAND" ? false : true
                            );
                            setSelectedPayment(e.target.value);
                          }}
                        >
                          <Radio value="CASHINHAND">
                            Cash In Hand / Bank Transfer
                          </Radio>
                          <Radio value="ZAIPAYMENT">Digital Wallet</Radio>
                        </Radio.Group>
                      </Form.Item>
                      <div>
                        <Card style={{ width: "100%", height: "fit-content" }}>
                          <Content>
                            <Space className="flex-space-between">
                              <Typography.Text>Amount Sent</Typography.Text>
                              <Typography.Text>
                                AUD {Number(aud)?.toFixed(2)}
                              </Typography.Text>
                            </Space>
                            <Space className="flex-space-between">
                              <Typography.Text>Amount Received</Typography.Text>
                              <Typography.Text>
                                NPR {Number(npr)?.toFixed(2)}
                              </Typography.Text>
                            </Space>
                            <Space className="flex-space-between">
                              <Typography.Text>Fee</Typography.Text>
                              <Typography.Text>
                                AUD {exchangeRates?.transferFee?.toFixed(2)}
                              </Typography.Text>
                            </Space>
                            {forLoyaltyPoints &&
                              ProfileDetails?.ProfileDetails?.loyaltyPoints >=
                                DashboardReducers?.ExchangeRates
                                  ?.loyaltyPoints && (
                                <>
                                  <Divider />
                                  <Space direction="vertical">
                                    <Typography.Text>
                                      You have{" "}
                                      {
                                        ProfileDetails?.ProfileDetails
                                          ?.loyaltyPoints
                                      }{" "}
                                      loyalty Points
                                    </Typography.Text>
                                    <Space size="large">
                                      <Typography.Text>
                                        Do you want to use loyalty points?
                                      </Typography.Text>
                                      <Switch
                                        defaultChecked={
                                          PoliPayReducers?.useLoyaltyPoints
                                        }
                                        onChange={(value: any) => {
                                          dispatch(UseLoyaltyPoints(value));
                                        }}
                                      />
                                    </Space>
                                    <Typography.Text
                                      style={{
                                        fontStyle: "italic",
                                        color: "#f4822a",
                                      }}
                                    >
                                      Note: {exchangeRates?.loyaltyRedeemedRate}
                                    </Typography.Text>
                                  </Space>
                                </>
                              )}
                            <Divider />
                            <Space className="flex-space-between">
                              <Typography.Text>Total Amount</Typography.Text>
                              <Typography.Text>
                                AUD{" "}
                                {PoliPayReducers?.useLoyaltyPoints === true
                                  ? (
                                      Number(aud) +
                                      Number(exchangeRates?.transferFee) -
                                      Number(
                                        exchangeRates?.convertedLoyaltyAmount
                                      )
                                    ).toFixed(2)
                                  : (
                                      Number(aud) +
                                      Number(exchangeRates?.transferFee)
                                    )?.toFixed(2)}
                              </Typography.Text>
                            </Space>
                          </Content>
                          <Divider />
                          {current === 4 &&
                            selectedPayment !== "ZAIPAYMENT" && (
                              <Button htmlType="submit" type="primary">
                                Pay
                              </Button>
                            )}
                        </Card>
                      </div>
                    </Form>
                    {selectedPayment === "ZAIPAYMENT" && (
                      <Card style={{ marginTop: "5.8rem" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            gap: "1rem",
                          }}
                        >
                          <h3>Available Funds</h3>
                          <Button
                            onClick={() => setIsAddFund(true)}
                            type="primary"
                            size="small"
                          >
                            Add Funds
                          </Button>
                        </div>
                        <Divider style={{ margin: "0.5rem 0" }} />
                        <h2
                          style={{
                            color: "#dc992b",
                            fontWeight: "bold",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          $ {zaiAccountRecord?.balance} AUD{" "}
                          <IoIosRefreshCircle
                            onClick={fetchZaiAccountDetail}
                            title="Refresh balance"
                            style={{
                              cursor: "pointer",
                              marginLeft: "0",
                              fontSize: "1.5rem",
                              color: "grey",
                            }}
                          />
                        </h2>
                        <Button
                          onClick={handleTransferZaiFund}
                          size="large"
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#dc992b",
                            border: "1rpx solid #dc992b",
                          }}
                          loading={isPaymentLoading}
                        >
                          Transfer Fund
                        </Button>
                      </Card>
                    )}
                  </div>
                </Card>
              </div>
            )}
            <div className="steps-action" style={{ marginTop: 20 }}>
              {((current > 0 && current < 2 && current !== 1) ||
                current === 0) && (
                <Button type="primary" onClick={next}>
                  Continue
                </Button>
              )}
              {current > 0 && (
                <Button style={{ marginLeft: 8 }} onClick={prev}>
                  Previous
                </Button>
              )}
            </div>
          </Col>
        </Row>

        <Modal
          open={isAddFund}
          onOk={() => {}}
          onCancel={() => {
            setIsAddFund(false);
          }}
          footer={false}
          closable={false}
        >
          <h3 style={{ fontWeight: "bold" }}>Funding your account</h3>
          <p>Add funds to your wallet via PayID for transfer?</p>

          <Card
            style={{
              fontWeight: "bold",
              padding: "0rem",
              fontSize: "1rem",
              background: "#cfcfcf20",
            }}
          >
            PayID: {zaiAccountRecord?.payId}
          </Card>
        </Modal>
      </Card>
    </>
  );
};

export default SendMoney;
